import React, { createContext, useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import AccountsSDK from '@livechat/accounts-sdk'
import api from '../api'
import { getCachedToken } from '../utils/auth'
import { removeCookie } from '../utils/cookies'
import useLocalStorage from './useLocalStorage'

const initUser = {
  name: '',
  avatar_url: '',
  email: '',
  license_id: '',
}

const defaultContext = {
  authorize: () => {},
  isAuthorized: false,
  logout: () => {},
  user: initUser,
}

const AuthContext = createContext(defaultContext)
const TOKEN_KEY = 'access_token'

const AUTH_TYPE = {
  iframe: 'iframe',
  popup: 'popup',
  redirect: 'redirect',
}

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useLocalStorage(TOKEN_KEY, getCachedToken())
  const [isAuthorized, setIsAuthorized] = useState(!!token)
  const [user, setUser] = useState(initUser)

  const authorize = async type => {
    try {
      const options = {
        client_id: process.env.GATSBY_ACCOUNTS_CLIENTID,
        server_url: process.env.GATSBY_ACCOUNTS_URL,
      }
      const accountsSdk = new AccountsSDK(options)
      let authorizeData

      switch (type) {
        case AUTH_TYPE.iframe:
          authorizeData = await accountsSdk.iframe(options).authorize()
          break

        case AUTH_TYPE.popup:
        default:
          authorizeData = await accountsSdk.popup(options).authorize()
          break
      }

      setToken(authorizeData[TOKEN_KEY])
    } catch (error) {
      if (type === AUTH_TYPE.popup) {
        // eslint-disable-next-line
        console.error(error)
      }
      logout(false)
    }
  }

  const logout = (deleteSessions = true) => {
    try {
      if (deleteSessions) {
        api.getAccounts().deleteSessions()
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
    }

    setToken(null)
    setIsAuthorized(false)
    setUser(initUser)
    removeCookie(TOKEN_KEY)
    window.sessionStorage.removeItem(TOKEN_KEY)
  }

  const fetchUserInfo = async () => {
    try {
      const data = await api.getLiveChat().getMe()
      const avatar = data?.avatar
      const avatar_url =
        avatar.indexOf('https://') === -1 ? `//${avatar}` : avatar

      setUser({
        name: data?.name || '',
        avatar_url: avatar_url || '',
        email: data?.email || '',
        license_id: data?.license_id || '',
      })
    } catch (error) {
      logout(false)
      // eslint-disable-next-line
      console.error(error)
    }
  }

  useEffect(() => {
    authorize(AUTH_TYPE.iframe)
    // eslint-disable-next-line
  }, [])

  useEffect(
    () => {
      api.initialize(token)
      setIsAuthorized(!!token)

      if (!!token) {
        fetchUserInfo()
      }
    },
    // eslint-disable-next-line
    [token]
  )

  return (
    <AuthContext.Provider value={{ authorize, isAuthorized, logout, user }}>
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node,
}

export const useAuth = () => useContext(AuthContext)
