import React from 'react'
import PropTypes from 'prop-types'
import { AuthProvider } from './src/hooks/useAuth'

import 'typeface-ibm-plex-mono'

require('prismjs/themes/prism-solarizedlight.css')
require('@livechat/design-system/dist/design-system.css')

const setupChatWidget = ({ userName = '', userEmail = '' }) => {
  window.__lc = window.__lc || {}
  window.__lc.license = 1520
  window.__lc.params = [
    { name: 'name', value: userName },
    { name: 'email', value: userEmail },
  ]
  ;(function() {
    var lc = document.createElement('script')
    lc.type = 'text/javascript'
    lc.async = true
    lc.src =
      ('https:' === document.location.protocol ? 'https://' : 'http://') +
      'cdn.livechatinc.com/staging/tracking.js'
    var s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(lc, s)
  })()
}

setupChatWidget({ userName: '', userEmail: '' })

const scrollTo = id => () => {
  const el = document.querySelector(id)
  if (el) return window.scrollTo(0, el.offsetTop - 20)
  return false
}

export const onRouteUpdate = ({ location: { hash } }) => {
  if (hash) {
    window.setTimeout(scrollTo(hash), 100)
  }
}

export const wrapRootElement = ({ element }) => (
  <AuthProvider>{element}</AuthProvider>
)

wrapRootElement.propTypes = {
  element: PropTypes.node,
}
