import axios from 'axios'
import { getRegion } from '../utils/region'

class AccountsClient {
  constructor(token) {
    this.instance = axios.create({
      baseURL: process.env.GATSBY_ACCOUNTS_URL,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
  }

  async getMe() {
    try {
      const response = await this.instance.get(`/v2/accounts/me`)

      return response.data
    } catch (error) {
      if (error.response.data?.error === 'unauthorized')
        throw new Error(`Unauthorized`)
      // eslint-disable-next-line
      console.error(error)
    }
  }

  async deleteSessions() {
    try {
      await this.instance.delete(`/v2/sessions`)
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
    }
  }
}

class LiveChatClient {
  constructor(token) {
    const region = getRegion(token)

    this.instance = axios.create({
      baseURL: process.env.GATSBY_LC_API_URL,
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Region': region,
      },
    })
  }

  async getMe() {
    try {
      const response = await this.instance.get(`/agents/me`, {
        headers: {
          'X-API-Version': 2,
        },
      })

      return response.data
    } catch (error) {
      if (error.response.data?.error === 'unauthorized')
        throw new Error(`Unauthorized`)
      // eslint-disable-next-line
      console.error(error)
    }
  }
}

let clients = {
  accounts: null,
  livechat: null,
  isInitialized: false,
}

const api = {
  initialize(token) {
    clients = {
      accounts: new AccountsClient(token),
      livechat: new LiveChatClient(token),
      isInitialized: true,
    }
  },

  getAccounts() {
    return clients.accounts
  },

  getLiveChat() {
    return clients.livechat
  },
}

export default api
