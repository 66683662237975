const REGION = {
  FRA: 'fra',
  DAL: 'dal',
  FRA_A: 'fra-a',
  FRA_B: 'fra-b',
}

const supportedRegions = Object.values(REGION)

export const getRegion = token => {
  const region = token?.split(':')[0]

  // See https://developers.livechat.com/docs/messaging/agent-chat-api/#data-centers
  if (supportedRegions.includes(region)) {
    return region
  }

  return undefined
}
