import compose from 'lodash/fp/compose'
import fromPairs from 'lodash/fp/fromPairs'
import map from 'lodash/fp/map'
import split from 'lodash/fp/split'
import partialRight from 'lodash/fp/partialRight'
import trimStart from 'lodash/fp/trimStart'
import join from 'lodash/fp/join'
import toPairs from 'lodash/fp/toPairs'

export const decodeQueryString = compose(
  fromPairs,
  map(
    compose(
      map(decodeURIComponent),
      split('=')
    )
  ),
  split('&'),
  partialRight(trimStart.convert({ fixed: false }), ['?'])
)

export const buildQueryString = compose(
  join('&'),
  map(
    compose(
      join('='),
      map(encodeURIComponent)
    )
  ),
  toPairs
)
