import { decodeQueryString, buildQueryString } from './queryString'

const KEY_CODES = {
  ENTER: 13,
  TAB: 9,
  ARROW_UP: 38,
  ARROW_DOWN: 40,
}

const canUseWindow = !!(typeof window !== 'undefined' && window.document)

const clearParams = () =>
  window.history.replaceState(null, null, window.location.pathname)

const getUpdates = (updates, skip, limit, query, categories) => {
  const updatesAfterSkipping = updates.slice(skip, updates.length)

  const withQuery = !!query
  const areCategoriesSelected = categories.length > 0

  const byQuery = update =>
    update.title.toLowerCase().includes(query.toLowerCase())

  const byCategory = update =>
    update.categories.some(category =>
      categories.includes(category.toLowerCase())
    )

  const getNumberOfPages = items => Math.ceil(items.length / 10)

  let numberOfPages = getNumberOfPages(updates)

  if (withQuery) {
    numberOfPages = getNumberOfPages(updates.filter(byQuery))
  }

  if (areCategoriesSelected) {
    numberOfPages = getNumberOfPages(updates.filter(byCategory))
  }

  if (withQuery && areCategoriesSelected) {
    numberOfPages = getNumberOfPages(updates.filter(byQuery).filter(byCategory))
  }

  let filteredUpdates = updatesAfterSkipping

  if (withQuery) {
    filteredUpdates = filteredUpdates.filter(byQuery)
  }

  if (areCategoriesSelected) {
    filteredUpdates = filteredUpdates.filter(byCategory)
  }

  const pageUpdates = filteredUpdates.slice(0, limit)

  return {
    numberOfPages,
    updates: pageUpdates,
  }
}

const toggleScroll = shouldEnable => {
  if (canUseWindow) {
    document.getElementsByTagName('body')[0].style.overflow = shouldEnable
      ? 'auto'
      : 'hidden'
  }
}

export {
  KEY_CODES,
  canUseWindow,
  decodeQueryString,
  buildQueryString,
  clearParams,
  getUpdates,
  toggleScroll,
}
